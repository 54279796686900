<template>
	<div class="organization">
		<div class="topbox">
			<div
				class="onetop nochoosetext"
				v-for="(item, index) in toplist"
				:key="index"
				@click="chooseTop(index)"
			>
				<span :class="{ activespan: index === topIndex }">{{ item }}</span>
				<div v-if="index === topIndex"></div>
			</div>
		</div>

		<div class="tablebox">
			<div class="addbox" @click="showAdd">
				<i class="el-icon-plus" style="color: #ffffff;"></i>
				<span>新增</span>
			</div>

			<div>
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column type="index" label="序号" width="100"></el-table-column>
					<el-table-column prop="settingName" label="名称"></el-table-column>

					<el-table-column label="操作">
						<template slot-scope="scope">
							<div class="twobtn">
								<div class="kanbox gai" @click="showEdit(scope.row)">
									<img src="@/assets/btnicon/image3.png" />
									改
								</div>

								<div class="kanbox shan" @click="showDelete(scope.row)">
									<img src="@/assets/btnicon/image6.png" />
									删
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>

				<div class="pagebox">
			<!-- 		<el-pagination
						background
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="currentPage4"
						:page-sizes="[100, 200, 300, 400]"
						:page-size="100"
						layout="total, sizes, prev, pager, next, jumper"
						:total="400"
					></el-pagination> -->
				</div>
			</div>
		</div>
		<edit-set @updataInfo="_settingList" ref="editSet"></edit-set>
		<add-set @updataInfo="_settingList" ref="addSet"></add-set>
		<delete-popup @deleteInfo='_deleteSetting' message="确认要删除此项设置吗" ref="deletePopup"></delete-popup>
	</div>
</template>

<script>
import { settingList,deleteSetting } from '@/network/api.js';
import editSet from './editSet.vue';
import addSet from './addSet.vue';
import deletePopup from '../../components/common/deletePopup.vue';
export default {
	data() {
		return {
			toplist: ['公司类型', '所属行业', '客户来源', '客户标签', '跟进方式', '合同类型', '付款方式','客户状态'],
			topIndex: 0,
			settingType: 1,
			currentPage4: 4,
			tableData: [],
			deleteId: '',
		};
	},
	name: 'systemSet',
	created() {
		this._settingList();
		this.setTitle()
	},
	components: {
		editSet,
		addSet,
		deletePopup
	},
	methods: {
		// 设置标题
		setTitle() {
			document.title = this.toplist[this.topIndex]
		},
		// 删除设置
		async _deleteSetting() {
			const res = await deleteSetting({
				settingId: this.deleteId
			})
			this.$refs.deletePopup.dialogVisible = false;
			this.$myMessage("删除成功",'success')
			this._settingList()
		},
		// 点击出现添加弹框
		showAdd() {
			this.$refs.addSet.addinfo.settingType = this.settingType
			this.$refs.addSet.dialogVisible = true
		},
		// 获取某项设置
		async _settingList() {
			const res = await settingList({
				settingType: this.settingType
			});
			console.log('设置数据', res);
			this.tableData = res.data;
			this.$store.dispatch('systemModule/_allSettingList');
		},
		// 点击出现修改弹框
		showEdit(info) {
			console.log(info);
			this.$refs.editSet.dialogVisible = true;
			this.$refs.editSet.addinfo = {
				settingId: info.settingId, //设置id
				settingName: info.settingName, //设置名称
				settingType: info.settingType //设置类型
			};
		},
		// 点击出现删除弹框
		showDelete(info) {
			this.deleteId = info.settingId
			this.$refs.deletePopup.dialogVisible = true;
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
		},

		// 点击顶部导航
		chooseTop(index) {
			this.topIndex = index;
			this.settingType = index + 1;
			this._settingList();
			this.setTitle()
		}
	}
};
</script>

<style scoped lang="scss">
::v-deep {
	.pagebox {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin: 6.25em 0 0 1.25em;
		padding: 0 1.25em 0 0;
		.el-input__inner {
			color: #666666;
			width: 3em;
			height: 2.25em;
			line-height: 2.25em;
		}
		.el-select {
			.el-input__inner {
				color: #666666;
				width: 8em;
				height: 2.25em;
				line-height: 2.25em;
			}
		}

		.el-input__icon {
			line-height: 2.25em;
		}
	}
}
.kanbox:active {
	opacity: 0.6;
}
.kanbox {
	cursor: pointer;
	width: 3.625em;
	height: 1.625em;
	background: #0091ff;
	border-radius: 0.8125em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.675em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffffff;
	img {
		width: 0.9375em;
		height: 1.0625em;
	}
	&.shan {
		background-color: #b3bcc9;
		margin: 0 0 0 1.25em;
		img {
			width: 0.9375em;
			height: 1em;
		}
	}
	&.gen {
		background-color: #fa7e33;
		margin: 0 0 0 1.25em;
		img {
			width: 0.8125em;
			height: 1.0625em;
		}
	}
	&.gai {
		background-color: #0091ff;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.875em;
		}
	}
	&.zeng {
		background-color: #9f7eff;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.8125em;
		}
	}
	img {
		width: 1em;
		height: 0.9375em;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: space-around;
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
thead {
}
.addbox:active {
	opacity: 0.6;
}
.addbox {
	margin: 0 0 1.25em 0;
	cursor: pointer;
	width: 6.75em;
	height: 2.25em;
	background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
	border-radius: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 0.875rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		margin: 0 0 0 1.25em;
	}
}
::v-deep {
	.el-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.chongzhi {
	width: 5.625em;
	height: 2.25em;
	font-size: 0.875em;
	margin: 0 0 0 1.25em;
}
.chaxun {
	margin: 0 1.25em 0 2.5em;
	width: 5.625em;
	height: 2.25em;
	background: rgba(50, 134, 255, 0.1);
	// border-radius: 0.25em;
	border: 1px solid #4d91ff;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #3286ff;
}
.tabletop {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
::v-deep {
	.el-input__inner {
		color: #666666;
		width: 10.5em;
		height: 2.25em;
		line-height: 2.25em;
	}
	.el-input__icon {
		line-height: 2.25em;
	}
}
::v-deep {
	.selectbox {
		margin: 0 0 0 2.5em;
		display: flex;
		align-items: center;
		// overflow: hidden;
		span {
			font-size: 0.875em;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			margin: 0 0.6875em 0 0;
		}
		.el-input__inner {
			color: #666666;
			width: 9.75em;
			height: 2.25em;
			line-height: 2.25em;
		}
	}
}

.tablebox {
	box-sizing: border-box;
	background-color: #ffffff;
	width: calc(100% - 2.5em);
	padding: 1rem 1.25em;
	margin: 0.9375em auto 0;
}
.inputbox {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 0.25em;
	border: 1px solid #e4e4e4;
	height: 2.25em;
	width: 12.5em;
	justify-content: space-between;
	input {
		outline: none;
		border: 0;
		width: 10.25em;
		font-size: 0.875em;
		padding: 0 0.375em 0 0.875em;
	}
	div {
		width: 2.25em;
		height: 2.25em;
		background: #faf9fb;
		border-radius: 0px 0.25em 0.25em 0px;
		border: 1px solid #e4e4e4;
	}
}
.onetop {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 0 3.75em 0 0;
	cursor: pointer;
	span {
		font-size: 1.125em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #444444;
		&.activespan {
			font-size: 1.125em;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #2d74f4;
		}
	}
	div {
		width: 1.875em;
		height: 0.1875em;
		position: absolute;
		bottom: 0;
		background-color: #3286ff;
	}
}
.topbox {
	padding: 0 1.0625em;
	display: flex;
	align-items: center;
	width: calc(100% - 2.5em);
	height: 3.25em;
	background-color: #ffffff;
	margin: 0.9375em auto 0;
}
@media screen and (max-width: 1920px) {
	.organization {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.organization {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.organization {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.organization {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.organization {
		font-size: 12px;
	}
}
</style>
