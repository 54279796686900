<template>
	<div class="addtan">
		<el-dialog
			:show-close="false"
			width="600px"
			:visible.sync="dialogVisible"
			:before-close="handleClose"
		>
			<div class="tanbox">
				<div class="titlebox">
					<span>添加</span>
					<i @click="handleClose" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
				</div>
				<div class="formbox">
					<div class="oneinput">
						<div class="inputtitle">
							<span>*</span>
							设置名称
						</div>
						<el-input v-model="addinfo.settingName"></el-input>
					</div>
					<div class="twobtn">
						<div @click="handleClose">取消</div>
						<div class="save" @click="_addSetting">保存</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import { addSetting } from "@/network/api.js"
	
export default {
	data() {
		return {
			dialogVisible: false,
			addinfo: {
				// settingId: '',			//设置id
				settingName: '',			//设置名称
				settingType: '',			//设置类型
			}
		};
	},

	methods: {
		// 添加跟进
		async _addSetting() {
			if (!this.addinfo.settingName) return this.$myMessage('请输入名称','error')
			const res = await addSetting(this.addinfo)
			this.$myMessage('编辑成功','success')
			this.handleClose()
			this.$emit('updataInfo')
		},

		handleClose() {
			this.dialogVisible = false
			this.addinfo = {
				// settingId: '',			//设置id
				settingName: '',			//设置名称
				settingType: '',			//设置类型
			}
		}
	}
};
</script>

<style scoped lang="scss">
	.oneinput {
		display: flex;
		align-items: center;
		margin: 20px 0 0;
	}


::v-deep {
	.el-input__inner {
		width: 350px !important;
		height: 40px !important;
	}
	.el-input {
		width: 350px !important;
		height: 40px !important;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 70px 0 0 0;
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 180px;
		height: 40px;
		background: #f7f7f7;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		&.save {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
			margin: 0 0 0 50px;
		}
	}
}

.inputtitle {
	margin: 0 15px 0 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #666666;
	span {
		color: #0091ff;
		margin: 0 10px 0 0;
	}
}
.formbox {
	width: 100%;
	padding: 30px 40px;
	

}
@media screen and (max-width: 1920px) {
	.addtan {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.addtan {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.addtan {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.addtan {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.addtan {
		font-size: 12px;
	}
}
.titlebox {
	border-bottom: 1px solid #eeeeee;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 52px;
	padding: 0 20px;
	i {
		cursor: pointer;
	}
	span {
		font-size: 1.125rem;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #303030;
	}
}
::v-deep {
	.el-dialog__header {
		padding: 0;
	}
	.el-dialog__body {
		padding: 0;
	}
	.el-dialog {
		border-radius: 10px;
	}
}
</style>
